export const WIDGET_WIDTH = 320;
export const WIDGET_HEIGHT = 360;
export const COLORS = ["#54d8fe", "#ff8373", "#ffdb80", "#a3a0fb", "#67ffb4"];
export const COLORS_GRAPH = [
  "#51bce3",
  "#da548a",
  "#f1893d",
  "#8e7ccd",
  "#3dc179"
];

export const ASSETS_INFO = [
  {
    name: "FXTB",
    text: "FinEx Cash Equivalents UCITS ETF (USD) Краткосрочные облигации США",
    country: "USA",
    image: "usa.svg"
  },
  {
    name: "FXUS",
    text:
      "FinEx USA UCITS ETF Акции / США долларовый инструмент инвестирования в крупнейшие американские компании",
    country: "USA",
    image: "usa.svg"
  },
  {
    name: "FXRU",
    text:
      "FinEx Tradable Russian Corporate BondsUCITS ETF (USD) Еврооблигации российских эмитентов",
    country: "Russia",
    image: "russia.svg"
  },
  {
    name: "FXRL",
    text: "FinEx Russian RTS Equity UCITS ETF (USD) Акции российских компаний",
    country: "Russia",
    image: "russia.svg"
  },
  {
    name: "FXDE",
    text:
      "FXDE / FinEx Germany UCITS ETF Акции /Германия. Это вложение в акции Германии, лидирующую экономику Европы",
    country: "Germany",
    image: "germany.svg"
  }
];
