import React, { Component } from "react";
import ReactDOM from "react-dom";
import AutoScale from "react-auto-scale";
import { BarChart, XAxis, YAxis, Bar, Cell } from "recharts";
import { COLORS } from "../shared/config";

const BarNode = document.getElementById("bar_chart");
const BarNode_Tab = document.getElementById("bar_chart_tab");
const BarNode_Mob = document.getElementById("bar_chart_mob");

export default class BarPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      data: this.props.dataRow.map((d) => {
        return {
          amount: Math.round(d.amount * 100),
          name: d.name,
        };
      }),
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let node = "";

    if (this.state.width > 991) {
      node = BarNode;
    } else {
      if (this.state.width > 479) {
        node = BarNode_Tab;
      } else {
        node = BarNode_Mob;
      }
    }

    const data = this.props.dataRow.map((d) => {
      return {
        amount: Math.round(d.amount * 100),
        name: d.name,
      };
    });

    const legend = data.map((d, i) => {
      return (
        <g key={i}>
          <text
            x={22}
            y={18 + i * 46}
            dy={0}
            fontSize="23"
            fill={"#515060"}
            textAnchor="middle"
          >
            {d.name}
          </text>

          <text
            x={60}
            y={18 + i * 46}
            dy={0}
            dx={d.amount < 10 ? 2 : 0}
            fontSize="23"
            fill={"#808490"}
            textAnchor="middle"
          >
            {d.amount}
          </text>
          <text
            x={68}
            y={18 + i * 46}
            dx={10}
            fontSize="23"
            fill={"#808490"}
            textAnchor="middle"
          >
            {"%"}
          </text>
        </g>
      );
    });
    return ReactDOM.createPortal(
      // <ResponsiveContainer width="200" height="100%">

      // </ResponsiveContainer>
      <AutoScale className="scale bar-scale">
        <BarChart
          data={data}
          width={this.state.width > 991 ? 240 : 320}
          height={240}
          layout="vertical"
          className="barchart"
        >
          <XAxis
            type="number"
            hide={true}
            domain={[0, Math.floor(this.props.maxPortionValue * 100)]}
          />
          <YAxis hide={true} type="category" dataKey="name" />

          {legend}

          <Bar
            radius={[6, 6, 6, 6]}
            barSize={
              this.state.width > 991 ? 12 : this.state.width < 479 ? 12 : 14
            }
            dataKey="amount"
          >
            {this.props.dataRow.map((entry, index) => {
              const color = COLORS[index];
              return <Cell key={`cell-${index}`} fill={color} />;
            })}
          </Bar>
        </BarChart>
      </AutoScale>,
      node
    );
  }
}
