import React, { Component } from "react";
import ReactDOM from "react-dom";
import { COLORS } from "../shared/config";
import AnimatedNumber from "animated-number-react";

const RatioNode = document.getElementById("ratio_node");

export default class RatioChart extends Component {
  render() {
    let { bonds, equity } = this.props.selectRowFromMainData();
    ////console.log("RatioChart bonds, equity", bonds, equity);

    let equity_r = Math.round(equity * 10000) / 100;
    let bonds_r = Math.round(bonds * 10000) / 100;
    return ReactDOM.createPortal(
      <>
        <div className="asset-ratio-block w-clearfix">
          <div className="asset-ratio-label-block">
            <AnimatedNumber
              value={equity_r}
              formatValue={(v) => Math.round(v) + "%"}
              duration={600}
              easing="easeInOutQuad"
            />
          </div>

          <div className="gray-sausage">
            <div
              className="inside-sausage"
              style={{
                backgroundColor: COLORS[3],
                width: Math.round(equity * 10000) / 100 + "%",
              }}
            ></div>
          </div>

          <div className="asset-ratio-block ratio-legend">акции</div>
        </div>
        <div className="asset-ratio-block w-clearfix">
          <div className="asset-ratio-label-block">
            {" "}
            <AnimatedNumber
              value={bonds_r}
              formatValue={(v) => Math.round(v) + "%"}
              duration={600}
              easing="easeInOutQuad"
            />
          </div>

          <div className="gray-sausage">
            <div
              className="inside-sausage"
              style={{
                backgroundColor: COLORS[4],
                width: Math.round(bonds * 10000) / 100 + "%",
              }}
            ></div>
          </div>

          <div className="asset-ratio-block ratio-legend">ОБЛИГАЦИИ</div>
        </div>
      </>,
      RatioNode
    );
  }
}
