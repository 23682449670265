import React, { Component } from "react";

import { ASSETS_INFO } from "../shared/config";
import SliderInput from "./SliderInput";
import PiePortfolio from "./PiePortfolio";
import BarPortfolio from "./BarPortfolio";
import RatioChart from "./RatioChart";
import AssetsFrame from "./AssetsFrame";

import loadPortfolios, { loadAssets, loadPriorities } from "../model/prepare";

export default class Main extends Component {
  state = {};
  selectedPeriodIndex = 0; // internal use
  selectedForecastIndex = 0; // internal use

  constructor(props) {
    super(props);

    this.changeForecast = this.changeForecast.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.selectRowFromMainData = this.selectRowFromMainData.bind(this);
    this.reloadComponent = this.reloadComponent.bind(this);

    this.bonds = 0.35;
    this.equity = 0.65;
    //TODO: initial state of bonds/equity
    this.state = {
      mainData: [],
      isLoading: true,
      graphKey: false,
      selectedPeriod: "0.5", // used for child components
      selectedForecast: "Neg-", // used for child components
      periodsNames: [],
      forecastsNames: [],
      assetNames: [],
      assetData: [],
    };
  }
  render() {
    if (this.state.isLoading) {
      return (
        <></>
        // <div className="spinner">
        //   <img src={spinner} alt={"spinner"}></img>
        // </div>
      );
    } else {
      let { dataRow, history } = this.selectRowFromMainData();
      this.orderData(dataRow);
      return (
        <div>
          <SliderInput
            forecastsNames={this.state.forecastsNames}
            changeForecast={this.changeForecast}
            selectedForecast={this.state.selectedForecast}
            periodsNames={this.state.periodsNames}
            changePeriod={this.changePeriod}
            selectedPeriod={this.state.selectForecastselectedPeriod}
          />
          <BarPortfolio
            maxPortionValue={this.state.maxPortionValue}
            dataRow={dataRow}
            isLoading={this.state.isLoading}
          />
          <PiePortfolio
            reloadComponent={this.reloadComponent}
            dataRow={dataRow}
            history={history}
            isLoading={this.state.isLoading}
          ></PiePortfolio>
          <RatioChart
            selectRowFromMainData={this.selectRowFromMainData}
            isLoading={this.state.isLoading}
          />
          <AssetsFrame
            isLoading={this.state.isLoading}
            data={this.state.assetsData}
          />
        </div>
      );
    }
  }
  orderData(data) {
    for (let i in data) {
      if (data[i].name === ASSETS_INFO[i].name) continue;
      let assetIndex = this.getIndex(data[i].name);
      let tmp = data[i];
      data[i] = data[assetIndex];
      data[assetIndex] = tmp;
    }
    ////console.log("ChartFrame:orderData", data);
  }
  getIndex(name) {
    for (let i in ASSETS_INFO) {
      if (ASSETS_INFO[i].name === name) return i;
    }
    return null;
  }
  componentDidMount() {
    loadPriorities().then((res) => {
      Promise.all([loadPortfolios(), loadAssets(res.data)]).then((data) => {
        //console.log("then", data);
        this.setState({
          mainData: data[0].data,
          assetsData: data[1],
          periodsNames: data[0].data.criteria.outlooks,
          forecastsNames: data[0].data.criteria.terms,
          maxPortionValue: this.getMaxPortionValue(data[0].data.portfolios),
        });
        //console.log("data fetched from server look at it: ", data[0].data);
        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 100);
        // this.selectRowFromMainData();

        //console.log("data fetched", this.state);
      });
    });
  }
  reloadComponent() {
    // this.forceUpdate();
  }
  changeForecast(val) {
    ////console.log("changeForecast()", val);
    setTimeout(() => {
      this.setState({
        selectedForecast: this.state.forecastsNames[val],
      });
    }, 200);
  }
  changePeriod(val) {
    ////console.log("changePeriod()", val);
    setTimeout(() => {
      this.setState({
        selectedPeriod: this.state.periodsNames[val],
      });
    }, 200);
  }

  getSlidersRows() {
    return {
      periodsRow: this.getPeriods(),
      forecastsRow: this.getForecasts(),
    };
  }
  getForecasts(data) {
    let acc = [];
    return data.reduce((acc, cur) => {
      if (acc.indexOf(cur.Outlook) < 0) {
        acc.push(cur.Outlook);
      }
      return acc;
    }, acc);
  }
  getPeriods(data) {
    let acc = [];
    //console.log("getPeriods: ", data);
    return data.reduce((acc, cur) => {
      if (acc.indexOf(cur.Term) < 0) {
        acc.push(cur.Term);
      }
      return acc;
    }, acc);
  }

  getMaxPortionValue(portfolios) {
    //console.log("this.getMaxPortionValue", portfolios);

    let max = 0;
    for (let i in portfolios) {
      let maxInPeriod = Object.values(portfolios[i]).reduce(
        (max, d, i, arr) => {
          let maxInRow = Math.max(...d);
          if (maxInRow > max) return maxInRow;
          else return max;
        },
        0
      );
      if (maxInPeriod > max) max = maxInPeriod;
    }
    //console.log("getMaxPortionValue", max);
    return max;
  }

  selectRowFromMainData() {
    let dataRow = [];

    let data = this.state.mainData;
    let period = this.state.selectedPeriod;
    let outlook = this.state.selectedForecast;

    dataRow = this.selectRow(outlook, period, data);
    ////////

    let Bonds = data.analytics[period][outlook].bond_ratio;
    let Equity = data.analytics[period][outlook].eq_ratio;
    let Return = data.analytics[period][outlook].return;

    let bonds = Math.round(Bonds * 100) / 100;
    let equity = Math.round(Equity * 100) / 100;
    let history = Math.round(Return * 1000) / 1000;

    this.bonds = bonds;
    this.equity = equity;
    this.history = history;

    // // //console.log ("returnin   selectRowFromMainData", {dataRow: dataRow,
    //   bonds: bonds,
    //   equity: equity,
    //   history: history,})

    return {
      dataRow: dataRow,
      bonds: bonds,
      equity: equity,
      history: history,
    };
  }

  selectRow(outlook, period, data) {
    let selectedRow = data.portfolios[period][outlook];
    ////console.log("selectRow 2", selectedRow);
    let outRow = [];
    for (let i in selectedRow) {
      outRow[i] = {
        name: data.etfs.tickers[i],
        amount: selectedRow[i],
      };
    }
    ////console.log("ourRow",  outRow);
    return outRow;
  }
}
