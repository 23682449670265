import React, { Component } from "react";
import { Slider } from "antd";
import { Row, Col } from "antd";
import * as d3 from "d3";

export default class SliderInput extends Component {
  render() {
    let periodsTipFormatter = (v) => {
      return this.props.periodsNames[v];
    };
    let forecastsTipFormatter = (v) => {
      return this.props.forecastsNames[v];
    };

    return (
      <>
        <Row className="period__slider-row">
          <Col span={4} className="slider__name">
            Срок
          </Col>
          <Col span={20} className="slider__col">
            <Slider
              className="period__slider"
              tipFormatter={periodsTipFormatter}
              tooltipVisible={false}
              min={0}
              max={this.props.periodsNames.length - 1}
              onChange={this.props.changePeriod}
              defaultValue={this.props.selectedPeriod}
              marks={Object.assign({}, ["0.5", "1", "3", "5", "10"])}
            />
          </Col>
        </Row>
        <Row className="forecast__slider-row">
          <Col span={4} className="slider__name">
            Прогноз
          </Col>
          <Col span={20} className="slider__col slider__col-forecast">
            <Slider
              className="forecast__slider"
              tipFormatter={forecastsTipFormatter}
              tooltipVisible={false}
              min={0}
              max={this.props.forecastsNames.length - 1}
              onChange={this.props.changeForecast}
              defaultValue={this.props.selectedForecast}
              marks={this.props.forecastsNames}
            />
          </Col>
        </Row>
      </>
    );
  }

  fixSwitch() {
    ////console.log("hello fixswitch");
    d3.selectAll(".ant-slider-handle").style(
      "transform",
      "translate(-50%, 1px)"
    );
  }

  componentDidUpdate(prevProps, prevState) {
    this.fixSwitch();
  }

  componentDidMount() {
    this.fixSwitch();
  }
}
