import React, { Component } from "react";
import ReactDOM from "react-dom";
import AssetCard from "./AssetCard";
import { ASSETS_INFO } from "../shared/config";

const AssetNode = document.getElementById("w-node-7f39a86b2dee-1f8b5118");
const AssetNode_Tab = document.getElementById("w-node-1376dff3936d-1f8b5118");

export default class AssetsFrame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    if (this.props.isLoading) return null;
    //console.log("AssetsFrame", this.props.data);

    const AssetsCards = this.props.data.map((e, i) => {
      if (this.state.width > 991) {
        if (i === 0) {
          return (
            <div className="etf etf-block" key={i}>
              <AssetCard
                datakey={i}
                info={ASSETS_INFO[i]}
                data={this.props.data[i].data}
                width={this.state.width}
              ></AssetCard>
            </div>
          );
        } else {
          return (
            <div className={"etf etf-block_" + (i + 1)} key={i}>
              <AssetCard
                datakey={i}
                info={ASSETS_INFO[i]}
                data={this.props.data[i].data}
                width={this.state.width}
              ></AssetCard>
            </div>
          );
        }
      } else {
        if (i === 0) {
          return (
            <div id={"quote1-block"} className="fxtb" key={i}>
              <div className="etf-block" key={i}>
                <AssetCard
                  datakey={i}
                  info={ASSETS_INFO[i]}
                  data={this.props.data[i].data}
                  width={this.state.width}
                ></AssetCard>
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={i}
              id={"quote" + (i + 1) + "-block"}
              className={ASSETS_INFO[i].name.toLowerCase()}
            >
              <div className={"etf-block etf-block_" + (i + 1)} key={i}>
                <AssetCard
                  datakey={i}
                  info={ASSETS_INFO[i]}
                  data={this.props.data[i].data}
                  width={this.state.width}
                ></AssetCard>
              </div>
            </div>
          );
        }
      }
    });
    if (this.state.width > 991) {
      return ReactDOM.createPortal(<>{AssetsCards}</>, AssetNode);
    } else {
      return ReactDOM.createPortal(
        <div className="w-layout-grid rates_grid">{AssetsCards}</div>,

        AssetNode_Tab
      );
    }
  }
}
