import React, { Component } from "react";
import { AreaChart, Area, YAxis } from "recharts";
import { COLORS_GRAPH } from "../shared/config";
import AutoScale from "react-auto-scale";

export default class AssetsFrame extends Component {
  getMonthData(data) {
    let now = new Date();

    let lastEntry = data.reduce(function (a, b) {
      return a.Date > b.Date ? a : b;
    });

    let lastEntryDate = new Date(lastEntry.Date);

    lastEntryDate.setDate(lastEntryDate.getDate() + 2);

    if (now > lastEntryDate)
      console.warn(
        "WARNING! Assets data is more than a day old! Please check input data"
      );

    let monthBeforeLastEntry = new Date(lastEntryDate);

    monthBeforeLastEntry.setDate(lastEntryDate.getDate() - 30);

    let monthData = data.filter((e) => {
      return e.Date > monthBeforeLastEntry;
    });
    monthData = monthData.sort((a, b) => {
      return a.Date - b.Date;
    });
    return monthData;
  }
  calculateDiff(data) {
    // ////console.log("calculDiff", data);

    let lastEntry = data.reduce(function (a, b) {
      return a.Date > b.Date ? a : b;
    });
    // console.log(
    //   "f,l",
    //   firstEntry,
    //   lastEntry,
    //   Math.round(
    //     ((lastEntry.Close - firstEntry.Close) / firstEntry.Close) * 10000
    //   ) / 100
    // );

    return lastEntry;
  }

  render() {
    const data = this.props.data.px_history.map((e) => ({
      Close: e,
      Date: new Date(),
    }));

    return (
      <>
        <div className="row1-quote-block">
          <div className="quote-block">
            <div className="ticker-and-flag-block">
              <div className="ticker-block">{this.props.data.ticker}</div>
              <div className="flag-block">
                <img
                  src={"images/" + this.props.data.flag}
                  alt=""
                  className="flag-quote-block"
                />
              </div>
            </div>
            <div className="price-and-change-block">
              <div className="price-quote-block">{this.props.data.px_last}</div>
              <div
                className="change-quote-block _2"
                style={{ color: this.props.data.r < 0 ? "red" : "#00b934" }}
              >
                {this.props.data.r < 0 ? "" : "+"}
                {Math.round(this.props.data.r * 10000) / 100}%
              </div>
            </div>
          </div>
          <div className="chart-block w-clearfix">
            <div
              className="asset_period"
              style={{ color: COLORS_GRAPH[this.props.datakey] }}
            >
              {this.props.data.r_period}
            </div>
            <AutoScale>
              <AreaChart
                baseValue="dataMin"
                width={120}
                height={50}
                data={data}
              >
                <defs>
                  <linearGradient
                    id={"colorUv" + this.props.datakey}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop
                      offset="5%"
                      stopColor={COLORS_GRAPH[this.props.datakey]}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="100%"
                      stopColor={COLORS_GRAPH[this.props.datakey]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <YAxis
                  type="number"
                  domain={["dataMin", "dataMax"]}
                  hide={true}
                />

                <Area
                  type="linear"
                  dataKey="Close"
                  stroke={COLORS_GRAPH[this.props.datakey]}
                  fill={"url(#colorUv" + this.props.datakey + ")"}
                />
              </AreaChart>
            </AutoScale>
          </div>
        </div>

        <div className="etf-description-block">
          {this.props.data.description}
        </div>
      </>
    );
  }
}
